import React, {useContext} from 'react';
import { MenuContext } from "../MenuManager";
import cn from 'classnames'; 

import { Linkedin, Dribbble } from "react-feather";

import './style.scss';



const internalLinks = [
  {
    url: "/who-we-are/",
    component: <span>Who We Are</span>,
    alt: "Who We Are",
    img:
      "https://images.unsplash.com/photo-1473042904451-00171c69419d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=4499&q=80",
  },
  {
    url: "/what-we-do/",
    component: <span>Our Services</span>,
    alt: "Our Services",
    img:
      "https://images.unsplash.com/photo-1499951360447-b19be8fe80f5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=4470&q=80",
  },
  {
    url: "/portfolio/",
    component: <span>Portfolio</span>,
    alt: "Our Portfolio",
    img:
      "/images/pwc_screenshot.jpg",
  },
  {
    url: "/our-clients/",
    component: <span>Our Clients</span>,
    alt: "Our Clients",
    img:
      "https://images.unsplash.com/photo-1590650213165-c1fef80648c4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=4470&q=80",
  },
  {
    url: "/contact-us/",
    component: <span>Contact Us</span>,
    alt: "Contact Us",
    img:
      "https://images.unsplash.com/photo-1516387938699-a93567ec168e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2671&q=80",
  },
  {
    url: "/",
    component: <span>Return Home</span>,
    alt: "Return to Homepage",
    img:
      "https://images.unsplash.com/photo-1495313196544-7d1adf4e628f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=4471&q=80",
  },
];

const externalLinks = [
  {
    url: "https://www.linkedin.com/in/stevepollak/",
    component: <Linkedin />,
  },
  {
    url: "https://www.dribbble.com",
    component: <Dribbble />,
  }
];



export default function MenuContent() {

		const { open } = useContext(MenuContext);

		return  (
		<div className="menu-holder">
			<div className={cn("menu-inside", { open })}>
				<div className="menu-nav-container">

					<ul className="internal-nav-links">
							{internalLinks.map((link) => (
								<li key={link.url}>
									<a href={link.url}>{link.component}</a>
									<img src={link.img}
                  alt={link.alt} /> 
								</li>
								))}
					</ul>

					<ul className="external-nav-links">
							{externalLinks.map((link) => (
								<li key={link.url}>
									<a href={link.url}>{link.component}</a>
									
								</li>
							))}
					</ul>

				</div>

			</div>
			
		</div> 
	)
};