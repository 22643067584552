import React, {useContext, useState, useEffect} from 'react';
import { MenuContext } from "../Menu/MenuManager";
import cn from 'classnames';

import {Plus} from 'react-feather';

import './style.scss'; 



export default function ContactButton () {

const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  )

  useEffect(() => {
    window
    .matchMedia("(min-width: 768px)")
    .addEventListener('change', e => setMatches( e.matches ));
  }, []);


	const { setOpen, open } = useContext(MenuContext); 

		return ( 
			<div >
      		{matches && (
			<button className={cn("contact-button", { open })} 
			onClick={event =>  window.location.href='/contact-us/'} >
			<span>Contact Us</span>
			<Plus />
			</button>)}
			{!matches && (
            <button className={cn("contact-button", { open })} 
			onClick={()=>setOpen(!open)} >
			<span>Contact Us</span>
			<Plus />
			</button>)}
				
    		</div> 
		);
};