import React, { useState, useRef, useEffect } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useInView } from 'react-intersection-observer';
import ContactButton from "../components/ContactButton";
import Header from "../components/Header";
import Footer from "../components/Footer";
import useBodyClass from "../hooks/useBodyClass";

import MenuManager from "../components/Menu/MenuManager";

import { Fade, Slide } from "react-awesome-reveal";
import "../styles/home.scss";


export default function About() {


	return (
		<><HelmetProvider>
			<Helmet>
				<title>About Us | Pollak Web Consulting</title>
				<meta  name="description" content="Pollak Web Consulting is an outgrowth of working with clients for more than 15 years and collaborating with them to achieve their digital dreams." />
				<link rel="canonical" href="http://pollakwebconsulting.com/who-we-are/" />
			</Helmet>
		</HelmetProvider>
			<MenuManager>
			<Header/>
			<div id="wrapper">
			<section id="about-page" className="container-fluid main-container">
			
			<div className="sectionHome row align-items-end vh-100 pb-5">
			<div className="hero-gradient"></div>		
			<div className="col-6">
			<div className="svg-container"><h1><Slide>Who We Are</Slide></h1></div>
			</div>
			<div className="col-5 d-flex justify-content-end">
			<ContactButton/>
			</div>
			</div>


			<div className="row about-top-section">
			<div className="col-md-2">
			<p className="sticky-lg-top py-5 paddingLeft">ABOUT US</p>
			</div>
			<div className="py-5 col-sm-12 col-md-8 col-lg-6">
			<Fade delay="20" duration="4000"><h2 className="headlineGradient pb-5">We focus on the customer. Everything else is details.</h2></Fade>

			<p className="larger-text">Pollak Web Consulting is an outgrowth of working with clients for more than 15 years and collaborating with them to achieve their digital dreams.</p>

			<p className="larger-text">By helping with everything from code to clicks to customers, we’ve aided hundreds of small businesses, executive leaders and in-house teams in presenting the best representation of themselves in both the digital and offline worlds.</p>

			<p className="larger-text">As a result of our extensive experience, we’re able to combine the tactical knowledge needed to execute a marketing plan with the strategic knowledge required to write such a plan.</p>

			<p className="larger-text">We follow a <a className="blueLink" href="/what-we-do/">five-step process</a> that’s almost guaranteed to produce results. And, while we’re big on strategy, we’re also ready to roll up our sleeves and push through the difficult, tedious work that’s necessary when executing a plan.</p>

			<p className="larger-text">With deep connections in the digital marketing world, we’ve been known to collaborate with some of the best marketers in the United States. Other times, the project is small enough for us to handle on our own.</p>

			<p className="larger-text">Either way, your project is in good hands with Pollak Web Consulting.</p>
			</div>
			</div>


			<div className="row about-top-section">
			
			<div className="col-md-2">
			<p className="sticky-lg-top py-5 ps-5">OUR VALUES</p>
			</div>
			<div className="py-5 col-sm-12 col-md-8 col-lg-6">
			<div className="row">
			<div className="col-md-6 client-box">
			<h4>Never stop learning</h4>
			<p>There are always new skills to learn and techniques to adapt. Leaders routinely explore new opportunities and act on them when appropriate.</p>
			</div>
			<div className="col-md-6 client-box">
			<h4>Good design is good business</h4>
			<p>We can cite dozens of examples such as Apple, Audi and Adobe. Companies who embrace great design always have a leg up on the competition.</p>
			</div>
			</div>
			<div className="row">
			<div className="col-md-6 client-box">
			<h4>Keep it simple</h4>
			<p>Don't make something complicated when a much simpler solution is at hand. Also, the simple solution is often the best one because there's less to go wrong, less to maintain and less to breakdown.</p>
			</div>
			<div className="col-md-6 client-box">
			<h4>Maintain integrity</h4>
			<p>We're proud of our work and our relationships. We strive to always be honest, transparent and trustworthy.</p>
			</div>
			</div>

			<div className="row">
			<div className="col-md-6 client-box">
			<h4>Being great isn't the same as good enough</h4>
			<p>We like to set high goals and then strive to reach them. If it can't be great, it's not worth it. Leaders should always insist on high standards.</p>
			</div>
			<div className="col-md-6 client-box">
			<h4>Be accountable</h4>
			<p>We show up and set out to accomplish the things we say we're going to do. If we don't accomplish those goals, we're obligated to accept and understand why we fell short.</p>
			</div>
			</div>
			
			<div className="row">
			<div className="col-md-12 client-box">
			<p><strong>Want to learn more about our values?</strong></p>
			<p>Please get in touch to find out more. <a className="blueLink" href="/contact-us/">Let&#8217;s talk soon</a>.</p>
			</div>
			</div>
			</div>
			</div>


			<Footer/>
			</section>
			</div>
			</MenuManager>

	</>		
		)


	}