import React, { useState, useRef, useEffect } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useInView } from 'react-intersection-observer';
import ContactButton from "../components/ContactButton";
import Header from "../components/Header";
import Footer from "../components/Footer";
import useBodyClass from "../hooks/useBodyClass";

import MenuManager from "../components/Menu/MenuManager";

import { Fade, Slide } from "react-awesome-reveal";
import "../styles/home.scss";

export default function WhatWeDo() {


	return (
			<>
			<HelmetProvider>
			<Helmet>
				<title>What We Do | Pollak Web Consulting</title>
				<meta  name="description" content="We help small business owners, executive leaders and in-house teams solve their digital dilemmas." />
				<link rel="canonical" href="http://pollakwebconsulting.com/what-we-do/" />
			</Helmet>
			</HelmetProvider>
			
			<MenuManager>
			<Header/>
			<div id="wrapper">
			<section id="about-page" className="container-fluid main-container">
		
		<div className="sectionHome row align-items-end vh-100 pb-5">
		<div className="hero-gradient"></div>		
		<div className="col-6">
		<div className="svg-container"><h1><Slide>Our Services</Slide></h1></div>
		</div>
		<div className="col-5 d-flex justify-content-end">
		<ContactButton/>
		</div>
		</div>


			<div className="row about-top-section">
			<div className="col-md-2">
			<p className="sticky-lg-top py-5 paddingLeft">WHAT WE DO</p>
			</div>
			<div className="py-5 col-sm-12 col-md-8 col-lg-6">
			<Fade delay="20" duration="4000"><h2 className="headlineGradient pb-5">We help small business owners, executive leaders and in-house teams improve their digital presence.</h2></Fade>
			<p className="larger-text">In our five-step process, we work with clients to identify the problem, draft a plan of attack, execute the plan and then analyze the results.</p>

			<div className="row py-4 d-flex align-items-center">
				
				<div className="col-sm-6 order-2 order-sm-1"><div><p><strong><span className="circle-number-white">1</span> Identify the Problem</strong></p>
				<p>Sometimes, the problem is easy to identify. You don’t have a website. Therefore, you need a website. Other times, the problems are not so simple to fix. Let’s say sales are down in the last six months and you’re not sure what happened. We’ll work with you to analyze the sales process — everything from your advertising to your landing pages to your shopping cart and even your customer service — to understand both where and why prospects are falling out of the sales funnel.</p>
				</div>
				</div>
				
				<div className="col-sm-6 order-1 order-sm-2 centeredBlock maskImage sectionWhatWeOfferFeaturedImages">
				<img className="img-fluid" src="https://images.unsplash.com/photo-1563986768494-4dee2763ff3f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2670&q=80" alt="" />
				</div>
				</div>

				<div className="row py-4 d-flex align-items-center">
				<div className="col-sm-6 centeredBlock maskImage sectionWhatWeOfferFeaturedImages">
				<img className="img-fluid" src="https://images.unsplash.com/photo-1526628953301-3e589a6a8b74?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2606&q=80" alt="" />
				</div>

				<div className="col-sm-6">
				<div>
				<p><strong><span className="circle-number-white">2</span> Determine our Goals</strong></p>
				<p>After identifying the problem, the next step is to determine goals that will support our need to solve the problem. For instance, if sales are down on your website, we may create a goal of improving sales by 25 percent over the next six months. If your brand needs more awareness, we can set of goal of improving search engine traffic by 50 percent over the next year (search engine traffic can be a proxy for brand awarensss).</p>
				</div>
				</div>
				</div>

				<div className="row py-4 d-flex align-items-center">
				<div className="col-sm-6 order-2 order-sm-1"><div><p><strong><span className="circle-number-white">3</span> Putting the plan into the GOST framework</strong></p>
				<p>Our favorite framework for solving problems that are more marketing oriented or that require a digital marketing strategy is to use the Goals, Objectives, Strategies and Tactics (GOST) framework. As the name implies, we would work with you to identify your goals and then define objectives tied to those goals. Next, we determine the best strategies for hitting those objectives and then finally decide what tactics to use in support of our strategies.</p>
				</div>
				</div>
				
				<div className="col-sm-6 order-1 order-sm-2 centeredBlock maskImage sectionWhatWeOfferFeaturedImages">
				<Slide direction="right"><img className="img-fluid" src="https://images.unsplash.com/photo-1532619675605-1ede6c2ed2b0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2670&q=80" alt="" /></Slide>
				</div>
				</div>


				<div className="row py-4 d-flex align-items-center">
				<div className="col-sm-6 centeredBlock maskImage sectionWhatWeOfferFeaturedImages">
				<Slide><img className="img-fluid" src="https://images.unsplash.com/photo-1521185496955-15097b20c5fe?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2250&q=80" alt="" /></Slide>
				</div>

				<div className="col-sm-6 centeredBlock"><div><p><strong><span className="circle-number-white">4</span> Executing the Plan</strong></p>
				<p>After we draft the plan, we need to execute. This can mean putting our heads down to write code or it can mean going into Google Adwords to launch an advertising campaign. Either way, the tactics in our plan need to be carried out and, most importantly, they need to be measured. If the results can’t be measured, it may be difficult to assess whether the strategies worked. So, we do everything we can to make sure everything is measurable.</p>
				</div></div>
				
				</div>
				
				<div className="row py-4 d-flex align-items-center">

				<div className="col-sm-6 order-2 order-sm-1 centeredBlock">
				<div>
				<p><strong><span className="circle-number-white">5</span> Analyzing the Results</strong></p>
				<p>After executing the plan, the next step is the analyze the results and determine if the plan worked. If the plan did work, that’s great. We can celebrate the win. Otherwise, we may need to go back to the plan, fine-tune the strategies and tactics and then execute once more. One way or another, we will work through the problem and find a way to solve it.</p>
				</div>
				</div>

				<div className="col-sm-6 order-1 order-sm-2 centeredBlock maskImage sectionWhatWeOfferFeaturedImages">
					<Slide><img className="img-fluid" src="https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2670&q=80" alt="" /></Slide>
				</div>


			</div>

			</div>

			</div>

			<div className="row about-top-section">
			

			<div className="col-md-2">
			<p className="sticky-lg-top py-5 paddingLeft">WHAT WE OFFER</p>
			</div>
			<div className="py-5 col-sm-12 col-md-8 col-lg-6">
			<div className="row">
			<div className="col-md-6 client-box">
			<h4>Website Design</h4>
			<p>We offer a wide range of web design services including responsive design and mobile app design.</p>
			</div>
			<div className="col-md-6 client-box">
			<h4>Web Development</h4>
			<p>We work in several types of technologies including HTML, CSS, Javascript, PHP, WordPress, Bootstrap, Angular and more.</p>
			</div>
			</div>
			<div className="row">
			<div className="col-md-6 client-box">
			<h4>Content &amp; Social Media</h4>
			<p>Want to improve your engagement on Facebook? Need more LinkedIn followers? Let us show you how.</p>
			</div>
			<div className="col-md-6 client-box">
			<h4>Email Marketing</h4>
			<p>Not sure if email still works? Let us demonstrate why it’s still one of the most effective ways to reach customers.</p>
			</div>
			</div>
			<div className="row">
			<div className="col-md-6 client-box">
			<h4>SEO (Search Engine Optimization)</h4>
			<p>Let us put together a plan to help you improve your search rankings and bring more traffic to your site.</p>
			</div>
			<div className="col-md-6 client-box">
			<h4>Local SEO</h4>
			<p>Local SEO can help businesses get found by local people in Google. Bring more customers to your doorstep!</p>
			</div>
			</div>
			

			


			<div className="row">
			<div className="col-md-6 client-box">
			<h4>Paid (PPC) Advertising</h4>
			<p>A paid advertising plan to greatly boost traffic and sales by targeting the right audience with the right message.</p>
			</div>
			<div className="col-md-6 client-box">
			<h4>Marketing Automation</h4>
			<p>Marketing automation takes the pain out of setting up landing pages, capturing leads and sending drip emails.</p>
			</div>
			</div>

			<div className="row">
			<div className="col-md-6 client-box">
			<h4>E-Commerce</h4>
			<p>Do you have a product to sell? Great. We can help you set up an online store, accept payments and ship your goods.</p>
			</div>
			<div className="col-md-6 client-box">
			&nbsp;
			</div>
			</div>

			
			<div className="row">
			<div className="col-md-12 client-box">
			<p><strong>And more.</strong></p>
			<p>Need help with an area of digital marketing or development that's not listed above? <a className="blueLink" href="/contact-us/">Get in touch</a> and we'll see what we can do.</p>
			</div>
			</div>
			</div>
			</div>


			<Footer/>
			</section>
			</div>
			</MenuManager>
</>
		)


	}