import React, { useState, useRef, useEffect } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useInView } from 'react-intersection-observer';
import ContactButton from "../components/ContactButton";
import Header from "../components/Header";
import Footer from "../components/Footer";
import useBodyClass from "../hooks/useBodyClass";


import MenuManager from "../components/Menu/MenuManager";

import { Fade, Slide } from "react-awesome-reveal";
import "../styles/home.scss";

export default function Clients() {


	return (
		<><HelmetProvider>
			<Helmet>
				<title>Clients | Pollak Web Consulting</title>
				<meta  name="description" content="Our clients come from companies large and small and from a wide range of industries. But they all have one thing in common: they want to build a great business. " />
				<link rel="canonical" href="http://pollakwebconsulting.com/our-clients/" />
				
			</Helmet>
			</HelmetProvider>
			
			<MenuManager>
			<Header/>
			<div id="wrapper">
			<section id="about-page" className="container-fluid main-container">
			
			<div className="sectionHome row align-items-end vh-100 pb-5">
			<div className="hero-gradient"></div>		
			<div className="col-6">
			<div className="svg-container"><h1><Slide>Our Clients</Slide></h1></div>
			</div>
			<div className="col-5 d-flex justify-content-end">
			<ContactButton/>
			</div>
			</div>


			<div className="row about-top-section">
			<div className="col-md-2">
			<p className="sticky-lg-top py-5 ps-5">OUR CLIENTS</p>
			</div>
			<div className="py-5 col-sm-12 col-md-8 col-lg-6">
			<Fade delay="20" duration="4000"><h2 className="headlineGradient pb-5">We love working with clients and seeing them succeed.</h2></Fade>

			<p className="larger-text">They come from companies large and small and from a wide range of industries. But they all have one thing in common: they want to build a great business. </p>
			<p className="larger-text">And, it&#8217;s important to us that they succeed in building that great business. Why? Besides the fact that our success depends upon their success, we work with clients who do amazing things in their work and in their community. When they succeed in both, it pays dividends not just for us but for the larger network of people, institutions and movements connected to that business.</p>
			<p className="larger-text">That&#8217;s what drives us to work harder and it’s why we’re different from than the typical agency where senior personnel often disappear after the contract is signed. That&#8217;s not how we operate.</p>
			<p className="larger-text">At Pollak Web Consulting, we believe great work doesn’t happen without a personal commitment to our clients. That personal touch is part of what makes us unique and lets our clients rest easy knowing they can always reach the people in charge of their project. We won&#8217;t disappear and we won&#8217;t disappoint.</p>
			<p className="larger-text">Our personal commitment is reflected in our project list, which has climbed into the 100s with clients coming from all over the United States and from dozens of industries.</p>
			</div>
			</div>

			<div className="row about-top-section">
			
			<div className="col-md-2">
			<p className="sticky-lg-top py-5 ps-5">SELECT CLIENTS INCLUDE</p>
			</div>
			<div className="py-5 col-sm-12 col-md-8 col-lg-6">
			
			
			<div className="row">
			<div className="col-md-6 client-box">
			<h4>Banyan Hills Technologies</h4>
			<p>We worked with this B2B SAAS company on all aspects of marketing including marketing strategy, digital marketing, website design, social media marketing and email marketing.</p>
			<span className="badge rounded-pill bg-primary">Marketing Strategy</span> <span className="badge rounded-pill bg-primary">Digital Marketing</span> <span className="badge rounded-pill bg-primary">Social Media Marketing</span> <span className="badge rounded-pill bg-primary">Email Marketing</span> <span className="badge rounded-pill bg-primary">Web Design</span> <span className="badge rounded-pill bg-primary">Web Development</span> <span className="badge rounded-pill bg-primary">SEO</span>
			</div>
			<div className="col-md-6 client-box">
			<h4>PDI Technologies</h4>
			<p>We helped PDI Technologies with their Wordpress website, their Pardot email program and their search engine optimization.</p>
			<span className="badge rounded-pill bg-primary">Digital Marketing</span> <span className="badge rounded-pill bg-primary">Email Marketing</span> <span className="badge rounded-pill bg-primary">Web Design</span> <span className="badge rounded-pill bg-primary">Web Development</span> <span className="badge rounded-pill bg-primary">SEO</span> 
			</div>
			</div>


			<div className="row">
			<div className="col-md-6 client-box">
			<h4>Cargomatic</h4>
			<p>We worked with this logistics company to help them with their Wordpress website as well as their Pardot instance.</p>
			<span className="badge rounded-pill bg-primary">Web Design</span>  <span className="badge rounded-pill bg-primary">Web Development</span>  <span className="badge rounded-pill bg-primary">Email Marketing</span>  
			</div>
			<div className="col-md-6 client-box">
			<h4>Access Point Financial</h4>
			<p>We helped Access Point Financial with their Pardot email program and their Wordpress website.</p>
			<span className="badge rounded-pill bg-primary">Email Marketing</span> <span className="badge rounded-pill bg-primary">CRM</span>
			</div>
			</div>


			<div className="row">
			<div className="col-md-6 client-box">
			<h4>NCR Corp.</h4>
			<p>We worked with this Fortune 500 company on all aspects of marketing including marketing strategy, digital marketing, website design, social media marketing and email marketing.</p>
			<span className="badge rounded-pill bg-primary">Marketing Strategy</span> <span className="badge rounded-pill bg-primary">Digital Marketing</span> <span className="badge rounded-pill bg-primary">Social Media Marketing</span> <span className="badge rounded-pill bg-primary">Email Marketing</span> <span className="badge rounded-pill bg-primary">Web Design</span> <span className="badge rounded-pill bg-primary">Web Development</span> <span className="badge rounded-pill bg-primary">PPC</span> <span className="badge rounded-pill bg-primary">SEO</span>
			</div>
			<div className="col-md-6 client-box">
			<h4>Salary.com</h4>
			<p>We helped Salary.com with the coding of a Pardot landing page.</p>
			<span className="badge rounded-pill bg-primary">Email Marketing</span> <span className="badge rounded-pill bg-primary">CRM</span>
			</div>
			</div>



			<div className="row">
			<div className="col-md-6 client-box">
			<h4>Callaway Gardens</h4>
			<p>We worked with this famed resort to help improve the SEO on their website and provided help with landing pages.</p>
			<span className="badge rounded-pill bg-primary">SEO</span> <span className="badge rounded-pill bg-primary">Landing Page Design</span>
			</div>
			<div className="col-md-6 client-box">
			<h4>Langdale Industries, Inc.</h4>
			<p>We helped Langdale overhaul their website, convert the old CMS to WordPress and improve their SEO.</p>
			<span className="badge rounded-pill bg-primary">Web Design</span> <span className="badge rounded-pill bg-primary">Web Development</span> <span className="badge rounded-pill bg-primary">SEO</span>
			</div>
			</div>
			
			<div className="row">
			<div className="col-md-6 client-box">
			<h4>Keeping It Specially Simple</h4>
			<p>This education start-up needed a new website. We created a custom theme built in WordPress.</p>
			<span className="badge rounded-pill bg-primary">Web Design</span> <span className="badge rounded-pill bg-primary">Web Development</span>
			</div>
			<div className="col-md-6 client-box">
			<h4>Right Your Resume</h4>
			<p>This unique resume consulting business needed a website with e-commerce capabilities, including special coding to hook into Pay Pal&#8217;s API for payment notifications.</p>
			<span className="badge rounded-pill bg-primary">Web Design</span> <span className="badge rounded-pill bg-primary">Web Development</span>
			</div>
			</div>
			<div className="row">
			<div className="col-md-6 client-box">
			<h4>Dental Depot Insurance</h4>
			<p>This dental insurance business needed a new website so they could collect leads. Pollak Web Consulting built a custom theme in WordPress to the client&#8217;s exact specifications.</p>
			<span className="badge rounded-pill bg-primary">Web Design</span> <span className="badge rounded-pill bg-primary">Web Development</span>
			</div>
			<div className="col-md-6 client-box">
			<h4>Insurance Depot</h4>
			<p>This health insurance business needed a fresh website in order to collect leads. Pollak Web Consulting built a custom theme in WordPress to the client&#8217;s exact specifications.</p>
			<span className="badge rounded-pill bg-primary">Web Design</span> <span className="badge rounded-pill bg-primary">Web Development</span>
			</div>
			</div>
			<div className="row">
			<div className="col-md-6 client-box">
			<h4>AME Siding, Inc.</h4>
			<p>This Crossville, Tenn., building supply firm needed a new website and Pollak Web Consulting provided both design and coding services.</p>
			<span className="badge rounded-pill bg-primary">Web Design</span> <span className="badge rounded-pill bg-primary">Web Development</span>
			</div>
			<div className="col-md-6 client-box">
			<h4>Health Plan Store</h4>
			<p>This Atlanta-based business needed a new website with paid memberships and payments integration. Pollak Web Consulting designed and coded a new WordPress theme with custom plugin coding.</p>
			<span className="badge rounded-pill bg-primary">Web Design</span> <span className="badge rounded-pill bg-primary">Web Development</span>
			</div>
			</div>
			<div className="row">
			<div className="col-md-6 client-box">
			<h4>How To Decorate</h4>
			<p>This website, one of the largest internior design blogs out there, needed help with content creation and SEO.</p>
			<span className="badge rounded-pill bg-primary">Content Marketing</span> <span className="badge rounded-pill bg-primary">SEO</span>
			</div>
			<div className="col-md-6 client-box">
			<h4>Gina Kellis, Esq.</h4>
			<p>This Atlanta-based attorney needed a website. Pollak Web Consultants built a new WordPress site.</p>
			<span className="badge rounded-pill bg-primary">Web Design</span> <span className="badge rounded-pill bg-primary">Web Development</span>
			</div>
			</div>
			<div className="row">
			<div className="col-md-6 client-box">
			<h4>Pomerance and Associates</h4>
			<p>This Atlanta-based public relations firm sought help with coding for a new website. Pollak Web Consulting provided the know-how to implement a new custom WordPress theme.</p>
			<span className="badge rounded-pill bg-primary">Web Design</span> <span className="badge rounded-pill bg-primary">Web Development</span>
			</div>
			<div className="col-md-6 client-box">
			<h4>Law Offices of Jill Pogash Michaels</h4>
			<p>This law firm asked for technical help with their website as well as an SEO audit. Pollak Web Consulting fulfilled both requests.</p>
			<span className="badge rounded-pill bg-primary">Web Design</span> <span className="badge rounded-pill bg-primary">Web Development</span> <span className="badge rounded-pill bg-primary">SEO</span>
			</div>
			</div>
			<div className="row">
			<div className="col-md-12 client-box">
			<p><strong>And many more.</strong></p>
			<p>We&#8217;d love to have you join our growing list of happy clients. <a className="blueLink" href="/contact-us/">Let&#8217;s talk soon.</a></p>
			</div>
			</div>
			</div>
			</div>


			<Footer/>
			</section>
			</div>
			</MenuManager>
		</>	

		)


	}