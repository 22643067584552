import React, { useState, useRef, useEffect } from "react";
import { useInView } from 'react-intersection-observer';
import ContactButton from "../components/ContactButton";
import Header from "../components/Header";
import Footer from "../components/Footer";
import useBodyClass from "../hooks/useBodyClass";

import MenuManager from "../components/Menu/MenuManager";

import "../styles/home.scss";


export default function NoPage() {


	return (

			<h2>404: Page Not Found Dude!</h2>

		)


	}