import React, { useState, useRef, useEffect } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useInView } from 'react-intersection-observer';
import ContactButton from "../components/ContactButton";
import Header from "../components/Header";
import Footer from "../components/Footer";
import useBodyClass from "../hooks/useBodyClass";

import MenuManager from "../components/Menu/MenuManager";

import { Fade, Slide } from "react-awesome-reveal";
import "../styles/home.scss";


export default function Portfolio() {


	return (
	<><HelmetProvider>
		<Helmet>
				<title>Portfolio | Pollak Web Consulting</title>
				<meta  name="description" content="Here are some examples of websites, landing pages, emails and social media marketing we've implemented for our clients over the years." />
				<link rel="canonical" href="http://pollakwebconsulting.com/portfolio/" />
			</Helmet>
		</HelmetProvider>
			<MenuManager>
			<Header/>
			<div id="wrapper">
			<section id="about-page" className="container-fluid main-container">
			
			<div className="sectionHome row align-items-end vh-100 pb-5">
			<div className="hero-gradient"></div>		
			<div className="col-6">
			<div className="svg-container"><h1><Slide>Our Portfolio</Slide></h1></div>
			</div>
			<div className="col-5 d-flex justify-content-end">
			<ContactButton/>
			</div>
			</div>


			<div className="row pb-5">
			<div className="col-md-2">
			<p className="sticky-lg-top py-5 paddingLeft text-light zindexSidebar">PROJECTS</p>
			</div>
			<div className="py-5 col-sm-12 col-md-8 col-lg-6">
			<Fade delay="20" duration="4000"><h2 className="text-light pb-5 headlineGradientLight">What do we do for our clients?</h2></Fade>

			<p className="larger-text text-light">Here are some examples of websites, landing pages, emails and social media marketing we've implemented for our clients over the years.</p>

			</div>
			</div>


			<div className="row pb-5">
			<div className="col-md-2">
			<p className="sticky-lg-top py-5 ps-5 text-light zindexSidebar"><strong>Aptitude Technologies website</strong></p>
			</div>
			<div className="col-md-4 py-5">
			<p className="larger-text text-light">Aptitude Technologies is a software development firm that specializes in providing outsourced talent for high growth software companies.</p>

<p className="larger-text text-light">The old website was outdated, using a flat color scheme and cluttered layouts. Beyond the aesthetics, the website did not adequately communicate what the firm offers prospective customers.</p>

<p className="larger-text text-light">Working with the client, we helped them update the color scheme, improve the website copy and refresh the layout.</p>
						</div>
			<div className="py-5 col-sm-12 col-md-4">
			<img className="img-fluid" src="/images/Aptitude_Tech_website_desktop_screenshot_ver_01.jpg" alt="Aptitude Technologies" />					
			</div>
			</div>

			<div className="row pb-5">
			<div className="col-md-2">
			<p className="sticky-lg-top py-5 ps-5 text-light zindexSidebar"><strong>Banyan Hills Technologies website</strong></p>
			</div>
			<div className="col-md-4 py-5">
			<p className="larger-text text-light">Banyan Hills Technologies is a software company located in the Atlanta area. We worked for Banyan Hills Technologies on almost every aspect of its marketing. The website was redesigned twice, once in 2019 and then again in 2022.</p>
			<p className="larger-text text-light">The site was built on Wordpress with a custom theme based on IBM's Carbon Design System.</p>
						</div>
			<div className="py-5 col-sm-12 col-md-4">
			<img className="img-fluid" src="/images/Banyan-Hills-Technologies_1000px.jpg" alt="Banyan Hills Technologies" />					
			</div>
			</div>

			<div className="row">
			<div className="col-md-2">
			<p className="sticky-lg-top py-5 ps-5 text-light zindexSidebar"><strong>Banyan landing page</strong></p>
			</div>
			<div className="col-md-4 py-5">
			<p className="larger-text text-light">We made extensive use of Hubspot forms on the Banyan Hills Technologies website. Here is an example of a lead capture landing page.</p>
						</div>
			<div className="py-5 col-sm-12 col-md-4">
			<img className="img-fluid" src="/images/Get-a-Demo-Banyan-LP.jpg" alt="Banyan Hills Technologies" />					
			</div>
			</div>

			<div className="row">
			<div className="col-md-2">
			<p className="sticky-lg-top py-5 ps-5 text-light zindexSidebar"><strong>Cargomatic website</strong></p>
			</div>
			<div className="col-md-4 py-5">
			<p className="larger-text text-light">Cargomatic is a supply chain and logistics company based in California. The site is built on Wordpress with a custom theme and uses Pardot form handler forms to capture leads.</p>
						</div>
			<div className="py-5 col-sm-12 col-md-4">
			<img className="img-fluid" src="/images/Cargomatic_website.jpg" alt="Cargomatic" />					
			</div>
			</div>


			<div className="row">
			<div className="col-md-2">
			<p className="sticky-lg-top py-5 ps-5 text-light zindexSidebar"><strong>Salary.com landing page</strong></p>
			</div>
			<div className="col-md-4 py-5">
			<p className="larger-text text-light">Salary.com is a software company that provides compensation data to busiensses. The company uses Pardot for marketing automation and we helped them create this landing page encourating users to resubscribe to Salary.com email newsletters, updates and promotions.</p>
			</div>
			<div className="py-5 col-sm-12 col-md-4">
			<img className="img-fluid" src="/images/Resubscribe-to-Salary-com.jpg" alt="Salary dot com landing page" />					
			</div>
			</div>


			<div className="row">
			<div className="col-md-2">
			<p className="sticky-lg-top py-5 ps-5 text-light zindexSidebar"><strong>GameChanger website</strong></p>
			</div>
			<div className="col-md-4 py-5">
			<p className="larger-text text-light">GameChanger owns several dozen kiosks throughout the southeastern United States where video game enthusiasts can not only buy video games but they can also sell their old games to GameChanger.</p>
			<p className="larger-text text-light">We created an eCommerce website for them using Wordpress and WooCommerce, taking payments via Authorize.net. In addition, we wrote custom code integrating with an API to allow website visitors to find out how much their old games are worth and then sell them back to GameChanger. If users want to sell, the website prints a special mailing label to track the transaction on the back end.</p>  
			</div>
			<div className="py-5 col-sm-12 col-md-4">
			<img className="img-fluid" src="/images/Gamechanger_Kiosk_full-screen.jpg" alt="GameChanger Kiosk website screenshot" />					
			</div>
			</div>

			<div className="row">
			<div className="col-md-2">
			<p className="sticky-lg-top py-5 ps-5 text-light zindexSidebar"><strong>Access Point Financial emails</strong></p>
			</div>
			<div className="col-md-4 py-5">
			<p className="larger-text text-light">Access Point Financial is an Atlanta-based financial company that specializing in hotel lending. The company uses Pardot for its marketing automation and we help craft the emails that go out to prospects.</p>  
			</div>
			<div className="py-5 col-sm-12 col-md-4">
			<img className="img-fluid" src="/images/Access_Point_Financial_emails.jpg" alt="Access Point Financial email screenshot" />					
			</div>
			</div>

			<div className="row">
			<div className="col-md-2">
			<p className="sticky-lg-top py-5 ps-5 text-light zindexSidebar"><strong>Social media marketing</strong></p>
			</div>
			<div className="col-md-4 py-5">
			<p className="larger-text text-light">A few example posts we've written and created over the years.</p>  
			</div>
			
			<div className="py-5 col-sm-12 col-md-4">
			<div className="row pb-4">
			<div className="col-sm-6"><img className="img-fluid pb-4" src="/images/Banyan-Hills-Technologies-Posts-LinkedIn-(4).jpg" alt="LinkedIn post screenshot" />

			<img className="img-fluid pb-4" src="/images/Banyan-Hills-Technologies-Posts-LinkedIn-(2).jpg" alt="LinkedIn post screenshot" />
			
			<img className="img-fluid pb-4" src="/images/President-s-Club-Toastmasters-East-Cobb-Posts-LinkedIn.jpg" alt="LinkedIn post screenshot" />

			<img className="img-fluid pb-4" src="/images/President-s-Club-Toastmasters-East-Cobb-Posts-LinkedIn-(1).jpg" alt="LinkedIn post screenshot" />
			</div>
			<div className="col-sm-6 pb-4">
			<img className="img-fluid pb-4" src="/images/Banyan-Hills-Technologies-Posts-LinkedIn-(3).jpg" alt="LinkedIn post screenshot" />
			<img className="img-fluid pb-4" src="/images/Banyan-Hills-Technologies-Posts-LinkedIn-(1).jpg" alt="LinkedIn post screenshot" />
			<img className="img-fluid pb-4" src="/images/President-s-Club-Toastmasters-East-Cobb-Posts-LinkedIn-(2).jpg" alt="LinkedIn post screenshot" />
			</div>
								
			</div>
			</div>
			
			</div>

			<Footer/>
			</section>
			</div>
			</MenuManager>

	</>		
		)


	}