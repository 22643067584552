import React from 'react';
import MenuButton from './MenuButton';
import MenuContent from './MenuContent';


export default function Menu() {
		return  (
		<div className="menu-wrapper">
		<MenuButton/>
		<MenuContent/>
		</div> 
	)
};