import React, { useState, useRef, useEffect } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useInView } from 'react-intersection-observer';
import ContactForm from "../components/ContactForm";
import ContactButton from "../components/ContactButton";
import Header from "../components/Header";
import Footer from "../components/Footer";
import useBodyClass from "../hooks/useBodyClass";

import {ArrowRightCircle} from 'react-feather';


import MenuManager from "../components/Menu/MenuManager";


import "../styles/home.scss";

export default function Contact() {


	return (
			<>
			<HelmetProvider>
			<Helmet>
				<title>Contact Us | Pollak Web Consulting</title>
				<meta  name="description" content="Please use this page to contact us. We look forward to hearing from you and getting started on building something new." />
			</Helmet>
			</HelmetProvider>
			<MenuManager>
			<Header/>

			<div id="wrapper">
			<section id="about-page" className="container-fluid main-container">
		
			<div className="row about-top-section">
			<div className="col-md-2">
			<p className="sticky-lg-top py-5 paddingLeft">CONTACT US</p>
			<div className="d-md-none py-5"><h4><a className="blueLink" href="/">Return to home <ArrowRightCircle /></a></h4></div>
			</div>
			<div className="py-5 col-sm-12 col-md-8 col-lg-6 min-vh-100">

						<h2 className="headlineGradient">Let's Chat</h2>

			<p className="larger-text">Send us a message and we'll get back in touch within 24 hours. Thanks!</p>

			<ContactForm />

			<p className="mt-5 larger-text">You can also email or text us via the below methods:</p>

			<p className="larger-text">Email: <a className="blueLink" href="mailto:steve@pollakwebconsulting.com">steve@pollakwebconsulting.com</a></p>

			<p className="larger-text">Phone/Text: <a className="blueLink" href="tel:4045795768">(404) 579-5768</a></p>







			</div>
			</div>



			<Footer/>
			</section>
			</div>
			</MenuManager>
		</>	

		)


	}