import React from "react";

const FormMessage = () => {
  return (
    <div className="formMessageText">
      <p className="larger-text text-success py-4">Thank you! Your message has been sent.</p>
    </div>
  );
};

export default FormMessage;