import React from 'react';
import ReactDOM from 'react-dom/client';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from './containers/Home';
import About from './containers/About';
import Clients from './containers/Clients';
import WhatWeDo from './containers/WhatWeDo';
import Contact from './containers/Contact';
import Portfolio from './containers/Portfolio';
import NoPage from './containers/NoPage';

import './App.scss';

function App() {
  return (
    <div>
    <BrowserRouter>
      <Routes>
        <Route index element={<Home/>} />
        <Route path="/home" element={<Home/>} />
        <Route path="/who-we-are/" element={<About/>} />
        <Route path="/what-we-do/" element={<WhatWeDo/>} />
        <Route path="/our-clients/" element={<Clients/>} />
        <Route path="/contact-us/" element={<Contact/>} />
        <Route path="/portfolio/" element={<Portfolio/>} />

        <Route path="*" element={<NoPage/>} />
      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
