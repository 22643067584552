import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

import FormMessage from "./FormMessage";

import './style.scss'; 


export default function ContactForm () {

  const [show, setShow] = useState(false);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_28xy8hq', 'template_cm619q6', form.current, 'njFYOqwy5H9UZuXn7')
      .then((result) => {
          //console.log("Email sent successfully!");
          //console.log(result.text);
          setShow(!show);

      }, (error) => {
         //console.log("Email error occured.");
          //console.log(error.text);
      });
  };

  return (
<div>
{!show && (
    <form ref={form} onSubmit={sendEmail}>

<div className="row">
  <div className="col">
  <div className="mb-3">
  <label htmlFor="firstNameInput">First Name</label>
  <input type="text" name="userFirstName" id="firstNameInput" className="form-control" placeholder="" aria-label="First name" required/>
  </div>
  </div>
  <div className="col">
  <div className="mb-3">
  <label htmlFor="lastNameInput">Last Name</label>
  <input type="text" name="userLastName" id="lastNameInput" className="form-control" placeholder="" aria-label="Last name" required/>
  </div>
  </div>
</div>
    
  <div className="row">
  <div className="col">
  <div className="mb-3">
  <label htmlFor="emailInput">Email address</label>
  <input type="email" name="userEmail" id="emailInput" className="form-control" placeholder="" aria-label="Email Address" required/>
  </div>
  </div>
  </div>

<div className="row">
  <div className="col">
<div className="mb-3">
  <label htmlFor="phoneInput">Phone</label>
  <input type="tel" minLength="9" maxLength="14" name="userPhone" id="phoneInput" className="form-control" placeholder="" aria-label="Phone Number" required />
</div>
</div>
</div>


<div className="row">
<div className="col">
<div className="mb-3">
  <label htmlFor="messageTextarea">Message</label>
  <textarea name="userMessage" id="messageTextarea" className="form-control" placeholder="" aria-label="Message" required></textarea>
</div>
</div>
</div>
<div className="row">
<div className="col">
<div className="form-floating mb-3">
   <button className="footer-contact-button" type="submit" type="submit" value="Send">Send message</button>
</div>
</div>
</div>
    </form>
   )}
      {show && <FormMessage />}
      </div>
  );
};